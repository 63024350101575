<div class="site-section border-bottom">
    <div class="container">

      <div class="row justify-content-center mb-5">
        <div class="col-md-7 text-center border-primary">
          <h2 class="font-weight-light " style="color: #11e446">Notre equipe</h2>
        </div>
      </div>



      <ngb-carousel>
				  <ng-template ngbSlide>
              <div>
                  <div class="testimonial">
                    <figure class="mb-4">
                      <img src="./assets/img/azou.jpg" alt="Image" class="img-fluid mb-3">
                      <p class="ko">Assane Dieye</p>
                    </figure>
                    <blockquote>
                      <p class="ko">Gérant</p>
                    </blockquote>
                  </div>
                </div>
				  </ng-template>
				  <ng-template ngbSlide>
              <div>
                  <div class="testimonial">
                    <figure class="mb-4">
                      <img src="./assets/img/daba.jpg" alt="Image" class="img-fluid mb-3">
                      <p class="ko">Rokhaya Ndiour</p>
                    </figure>
                    <blockquote>
                      <p class="ko">
Transitaire
                      </p>
                    </blockquote>
                  </div>
                </div>
				  </ng-template>
				  <ng-template ngbSlide>
              <div>
                  <div class="testimonial">
                    <figure class="mb-4">
                      <img src="./assets/img/s1.jpg" alt="Image" class="img-fluid mb-3">
                      <p> Safiétou</p>
                    </figure>
                    <blockquote>
                      <p>Déclarante</p>
                    </blockquote>
                  </div>
                </div>
          </ng-template>
          
          <ng-template ngbSlide>
              <div>
                  <div class="testimonial">
                    <figure class="mb-4">
                      <img src="./assets/img/alune.jpg" alt="Image" class="img-fluid mb-3">
                      <p class="ko">Alioune Ba</p>
                    </figure>
                    <blockquote>
                      <p class="ko">
Transitaire
                      </p>
                    </blockquote>
                  </div>
                </div>
          </ng-template>
          <ng-template ngbSlide>
              <div>
                  <div class="testimonial">
                    <figure class="mb-4">
                      <img src="./assets/img/g2.jpg" alt="Image" class="img-fluid mb-3">
                      <p class="ko">Mouhamed Ndiaye</p>
                    </figure>
                    <blockquote>
                      <p class="ko">
Transitaire
                      </p>
                    </blockquote>
                  </div>
                </div>
          </ng-template>
          <ng-template ngbSlide>
              <div>
                  <div class="testimonial">
                    <figure class="mb-4">
                      <img src="./assets/img/g1.jpg" alt="Image" class="img-fluid mb-3">
                      <p class="ko">Abdoulaye Ndour</p>
                    </figure>
                    <blockquote>
                      <p class="ko">
Transitaire
                      </p>
                    </blockquote>
                  </div>
                </div>
          </ng-template>

          <ng-template ngbSlide>
              <div>
                  <div class="testimonial">
                    <figure class="mb-4">
                      <img src="./assets/img/s2.jpg" alt="Image" class="img-fluid mb-3">
                      <p class="ko">Aby Touré</p>
                    </figure>
                    <blockquote>
                      <p class="ko">
Transitaire
                      </p>
                    </blockquote>
                  </div>
                </div>
          </ng-template>
          
          <ng-template ngbSlide>
              <div>
                  <div class="testimonial">
                    <figure class="mb-4">
                      <img src="./assets/img/dave.jpg" alt="Image" class="img-fluid mb-3">
                      <p class="ko"> Daouda Mbengue</p>
                    </figure>
                    <blockquote>
                      <p class="ko">
Responsable export
                      </p>
                    </blockquote>
                  </div>
                </div>
          </ng-template>
          <ng-template ngbSlide>
              <div>
                  <div class="testimonial">
                    <figure class="mb-4">
                      <img src="./assets/img/g3.jpg" alt="Image" class="img-fluid mb-3">
                      <p class="ko">Mamadou Ndiaye </p>
                    </figure>
                    <blockquote>
                      <p class="ko">
Responsable commercial
                      </p>
                    </blockquote>
                  </div>
                </div>
          </ng-template>
          
          <ng-template ngbSlide>
              <div>
                  <div class="testimonial">
                    <figure class="mb-4">
                      <img src="./assets/img/g3.jpg" alt="Image" class="img-fluid mb-3">
                      <p class="ko">Ali Sow </p>
                    </figure>
                    <blockquote>
                      <p class="ko">
Responsable import
                      </p>
                    </blockquote>
                  </div>
                </div>
				  </ng-template>
          

				</ngb-carousel>




    