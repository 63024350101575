<!--carousel-->

<section class="about-area gray-bg section-padding">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-6 col-md-offset-3 col-lg-offset-3 col-sm-12 col-xs-12 center">
        <div class="area-title text-center wow fadeIn" >
          <h2>Bienvenue à Sabour Global </h2>
          <p>Sabour Global est une entreprise de transit permettant la circulation de marchandises d'un bureau de douane à un autre;d'un pays à un autre en suspension des droits douaniers. </p>
        </div>


      </div>
    </div>

  </div>
</section>

     <app-bdservice></app-bdservice>

     <div class="card-deck">
      <div class="card bd">
       <div class="blog-image">
                <img src="./assets/img/blog/blog_9.jpg" alt="">
              </div>
              <div class="blog-details text-center">
                <div class="blog-meta"><a href="#"><i class="fa fa-ship"></i></a></div>
                <h3><a href="single-blog.html">TRANSIT</a></h3>
                <p>Nos équipes spécialisées sont chargées du suivi de chaque clients  et de leurs comptes, grâce à l’aide de notre système informatique performent,qui permet de
                  communiquer</p>
                <a  class="read-more"  (click)="transitOn()" style="color: white">Lire la suite</a>
              </div>
           
      </div>
      <div class="card bd">
        <div class="blog-image">
          <img src="./assets/img/blog/blog_7.jpg" alt="">
        </div>
        <div class="blog-details text-center">
          <div class="blog-meta"><a href="#"><i class="fa fa-truck"></i></a></div>
          <h3><a href="single-blog.html">TRANSPORT</a></h3>
          <p>Par notre filiale SABOUR GLOBAL TRANSPORT (SG TRANS), nous assurons le transport national, et international a partir et vers plusieurs pays.    </p>
          <a class="read-more"  (click)="transpOn()" style="color: white">Lire la suite</a>
        </div>
      </div>
      <div class="card bd">
        <div class="blog-image">
          <img src="./assets/img/blog/blog_8.jpg" alt="">
        </div>
        <div class="blog-details text-center">
          <div class="blog-meta"><a href="#"><i class="fa fa-ship"></i></a></div>
          <h3><a >CONSIGNATION</a></h3>
          <p>Nous sommes également consignataire des navires, nous assurons la manutention, le dédouanement, l'évacuation, et la livraison de toute sorte de marchandise.
         
          </p>
          <a  class="read-more" (click)="consignOn()" style="color: white">Lire la suite</a>
        </div>
      </div>
      <div class="card bd">
        <div class="blog-image">
          <img src="./assets/img/blog/blog_5.jpg" alt="">
        </div>
        <div class="blog-details text-center">
          <div class="blog-meta"><a href="#"><i class="fa fa-leanpub"></i></a></div>
          <h3><a >CONSEIL</a></h3>
          <p>Etant un domaine complexe,avec beaucoup de procédure le client est confronté à un besoin innévitable d'avis d'expert pour mener à bien ses démarches administratives.        
          </p>
          <a  class="read-more" (click)="consignOn()" style="color: white">Lire la suite</a>
        </div>
      </div>
    </div>
