<ul></ul>
<div class="footer-area text-center bg-success">
  <div class="footer-area-bg"></div>

  <div class="footer-bottom-area wow fadeIn">
    <div class="container">
          <div class="single-footer-widget footer-about fo" style="text-align: center">
            <div class="row">
                <div class="col-md-1">                  
                  </div>
              <div class="col-md-3">
                <h2 class="footer-heading mb-4">Quick Links</h2>
                <ul class="list-unstyled">
                  <li><a>Acceuil</a></li>
                  <li><a>Service transit</a></li>
                  <li><a>Service transport</a></li>
                  <li><a>Service consignation</a></li>
                  <li><a>Service conseil</a></li>
                  <li><a>Contacts</a></li>
                </ul>
              </div>
              <div class="col-md-3">
                <h2 class="footer-heading mb-4">Products</h2>
                <ul class="list-unstyled">
                    <li><a>Acceuil</a></li>
                    <li><a>Service transit</a></li>
                    <li><a>Service transport</a></li>
                    <li><a>Service consignation</a></li>
                    <li><a>Service conseil</a></li>
                    <li><a>Contacts</a></li>
                  </ul>
              </div>
              <div class="col-md-3">
                <h2 class="footer-heading mb-4">Features</h2>
                <ul class="list-unstyled">
                    <li><a>Acceuil</a></li>
                    <li><a>Service transit</a></li>
                    <li><a>Service transport</a></li>
                    <li><a>Service consignation</a></li>
                    <li><a>Service conseil</a></li>
                    <li><a>Contacts</a></li>
                  </ul>
              </div>
            
            </div>
          </div>
     
    </div>
  </div>
  <div class="footer-copyright-area">
    <div class="container">
          <div class="footer-copyright wow fadeIn" style="text-align: center">
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
            <p>Copyright &copy; vipcode 2018</p>
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
          </div>

      </div>
  </div>
</div>
