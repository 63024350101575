<div class="site-section bg-image overlay" style="background-image: url('./assets/img/blog/blog_1.jpg');">
  <div class="container">
    <div class="row justify-content-center mb-5">
      <div class="col-md-7 text-center border-primary">
          <br/>
        <h2 class="font-weight-light text-primary" data-aos="fade"><span style="color: white">Service Conseil</span></h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="100">
        <div class="how-it-work-item">
          <span class="number">1</span>
          <div class="how-it-work-body">
  
            <p class="mb-5 fo">Grace à nos experts expérimentés nous vous accompagnons dans la préparation:
            </p>
            <ul class=" list-unstyled white">
              <li class="text-white"><mat-icon>check</mat-icon>documentaire</li>
              <li class="text-white"><mat-icon>check</mat-icon>bancaire</li>
              <li class="text-white"><mat-icon>check</mat-icon>maritime</li>
              <li class="text-white"><mat-icon>check</mat-icon>terrestre</li>
              <li class="text-white"><mat-icon>check</mat-icon>aérien</li>

            </ul>
          </div>
        </div>
      </div>

     

      <div class="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="300">
        <div class="how-it-work-item">
          <span class="number">2</span>
          <div class="how-it-work-body">
            <p class="mb-5 fo">Nous vous renseignons sur les modalités de transport:
            </p>
              <ul class=" list-unstyled white">
                <li class="text-white"><mat-icon>check</mat-icon>maritime</li>
                <li class="text-white"><mat-icon>check</mat-icon>terrestre</li>
                <li class="text-white"><mat-icon>check</mat-icon>aérien</li>
              </ul>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="300">
          <div class="how-it-work-item">
            <span class="number">3</span>
            <div class="how-it-work-body">
              <p class="mb-5 fo">Nous vous apportons des conseils :
              </p>
                <ul class=" list-unstyled white">
                  <li class="text-white "><mat-icon>check</mat-icon>Dans le dédouanement </li>
                  <li class="text-white"><mat-icon>check</mat-icon>Les avantages prévus par l'administration des douanes</li>
                  <li class="text-white"><mat-icon>check</mat-icon>Les conventions nationales et internationales.</li>
                </ul>
            </div>
          </div>
        </div>

     

      
    </div>
  </div>
</div>


