import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-consignation',
  templateUrl: './service-consignation.component.html',
  styleUrls: ['./service-consignation.component.css']
})
export class ServiceConsignationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
