<div class="site-section bg-image overlay" style="background-image: url('./assets/img/about/3.jpg');">
  <div class="container">
    <div class="row justify-content-center mb-5">
      <div class="col-md-7 text-center border-primary">
        <br/>
        <h2 class="font-weight-light text-primary " data-aos="fade"><span style="color: white;font-family: 'Times New Roman', Times, serif">Service transport</span></h2>
      </div>
    </div>
   <p class="trans">Par notre filiale SABOUR GLOBAL TRANSPORT (SG TRANS), nous assurons le
    transport à deux niveau:
  </p>
  <div class="row">
   

      <div class="col-md-6 col-lg-3 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="300">
          <div class="how-it-work-item">
            <div class="how-it-work-body">
          
            </div>
          </div>
        </div>

      <div class="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="300">
        <div class="how-it-work-item">
          <span class="number fo">1</span>
          <span class="example-fill-remaining-space"></span>

          <div class="how-it-work-body">
            <h2>National</h2>
            <p class="mb-5 fo">Nous vous assurons l'acheminement sans risque de vos conteneurs dans les meilleurs 
              délais grace nos gros porteurs et chauffeurs expérimentés dans ce domaine. </p>
             
          </div>
        </div>
      </div>

      <div class="col-md-6 col-lg-1 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="300">
          <div class="how-it-work-item">
            <div class="how-it-work-body">
          
            </div>
          </div>
        </div>

      <div class="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="300">
        <div class="how-it-work-item">
          <span class="number fo">2</span>
          <div class="how-it-work-body">
            <h2 class="fo">International</h2>
            <p class="mb-5 fo">Fini vos conteneurs qui n'arrivent plus à temps.Grace à Sabour global vos soucis n'ont plus leurs raisons d'être.Grace au suivi et collaboration avec les plus grandes sociétés de transport maritime nous vous assurons un service de qualité.   </p>
          </div>
        </div>
      </div>

     

    
    </div>
  </div>
</div>


