import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VisiteurService } from '../services/user.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private visiteurService: VisiteurService) { }
  public _visiteurform: FormGroup;

  ngOnInit() {
    this.visiteurService.getVisiteursFromServer();
    this.initForm();

  }

  initForm() {
    this._visiteurform = this.formBuilder.group({
      nom: [ '', Validators.required],
      preno: [ '', Validators.required],
      email: [ '', Validators.required],
      sujet: [ '', Validators.required],
      message: [ '', Validators.required],
    });
  }

  onSubmitForm() {
    this.visiteurService.AjouterVisiteur(this._visiteurform.value);
    // this.router.navigate(['/users']);
  }
}
