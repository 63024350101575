import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CorpService } from '../services/corp.service';

@Component({
  selector: 'app-navebar',
  templateUrl: './navebar.component.html',
  styleUrls: ['./navebar.component.css']
})
export class NavebarComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver,private  cor: CorpService) {}
  ngOnInit(){}

  AccOn() {
    this.cor.setAcc();
  }

  transitOn(){
    this.cor.setTransitserv();
  }
transpOn(){
  this.cor.setTranspserv();
}
consignOn(){
  this.cor.setConsignserv();
}
conseilOn(){
  this.cor.setConseilserv();
}

contactOn(){
  this.cor.setContact();
}
}
