<div class="site-section bg-image overlay" style="background-image: url('./assets/img/about/3.jpg');">
  <div class="container">
    <div class="row justify-content-center mb-5">
      <div class="col-md-7 text-center border-primary">
        <h2 class="font-weight-light text-primary " data-aos="fade"><span style="color: white;font-family: 'Times New Roman', Times, serif">Contact</span></h2>
      </div>
    </div>
<div class="site-section ">
  <div class="container">
    <div class="row">
      <div class="col-md-7 mb-5">

        <form [formGroup]="_visiteurform" (ngSubmit)="onSubmitForm()" class="p-5 bg-white">
         

          <div class="row form-group" >
            <div class="col-md-6 mb-3 mb-md-0" >
              <label class="text-black"  for="fname">Prenom</label>
              <input type="text" class="form-control" id="preno" name="preno" formControlName="preno" placeholder="Prenom" required>
            </div>
            <div class="col-md-6">
              <label class="text-black" for="lname">Nom</label>
              <input type="text" class="form-control" id="nom" name="nom" formControlName="nom" placeholder="Nom" required>
            </div>
          </div>

          <div class="row form-group">
            
            <div class="col-md-12">
              <label class="text-black" for="email">Email</label> 
              <input type="text" class="form-control" id="email" name="email" formControlName="email" placeholder="Email" required>
            </div>
          </div>

          <div class="row form-group">
            
            <div class="col-md-12">
              <label class="text-black" for="subject">Sujet</label> 
              <input type="text" class="form-control" id="sujet" name="sujet" formControlName="sujet" placeholder="Sujet" required>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-md-12">
              <label class="text-black" for="message">Message</label> 
              <textarea  class="form-control" id="message" name="message"  formControlName="message" cols="30" rows="7"  placeholder="Ecrire vos notes ou observations ici..."></textarea>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-md-12">
              <input type="submit" value="Envoyer Message" class="btn btn-primary py-2 px-4 text-white">
            </div>
          </div>


        </form>
      </div>
      <div class="col-md-5">
        
        <div class="p-4 mb-3 bg-white">
          <p class="mb-0 font-weight-bold">Adresse</p>
<ul>
  <li>Immeuble NDINDY C Suite 39</li>
  <li>Rue Marchand x Autoroute</li>
  <li>DAKAR SENEGAL</li>

</ul>
          <p class="mb-0 font-weight-bold">Telephone</p>
          <ul>
            <li><mat-icon>call</mat-icon>+221 (33) 822 89 90</li>

          </ul>


        
          <p class="mb-0 font-weight-bold">Fax</p>
          <p class="mb-4"><a href="#">+221 (33) 822 72 73</a></p>


          <p class="mb-0 font-weight-bold">Adresse Email</p>
          <p class="mb-0"><a href="#">courrier@sabourglobal.com</a></p>

        </div>
        

      </div>
    </div>
  </div> 
</div>
</div>
</div>