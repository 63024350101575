<div class="site-section bg-image overlay" style="background-image: url('./assets/img/blog/blog_1.jpg');">
  <div class="container">
    <div class="row justify-content-center mb-5">
      <div class="col-md-7 text-center border-primary">
          <br/>

        <h2 class="font-weight-light text-primary" data-aos="fade"><span style="color: white">Service transit</span></h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="100">
        <div class="how-it-work-item">
          <span class="number fo">1</span>
          <div class="how-it-work-body">
            <h2 class="fo">Suivi client</h2>
            <p class="mb-5 fo">Nos équipes spécialisées sont chargées du suivi de chaque clients et de leurs
              comptes, grâce à l’aide de notre système informatique performent, qui permet de
              communiquer aux clients les situations périodiques liées à leurs transactions.</p>
          </div>
        </div>
      </div>

     

      <div class="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="300">
        <div class="how-it-work-item">
          <span class="number fo">2</span>
          <div class="how-it-work-body">
            <h2>Assistance client</h2>
            <p class="mb-5 fo">Nous assistons nos clients pour les faire bénéficier des avantages fiscaux, dans le
              cadre des accords de libre échange et ou d’exonération.Nous proposons toutes les facilités douanières à savoir :</p>
              <ul class="list-unstyled white">
                <li class="text-white"><mat-icon>check</mat-icon> Procédures douanières spécifiques.</li>
                <li class="text-white"><mat-icon>check</mat-icon>Facilités de cautionnement, dispense totale ou partielle.</li>
              </ul>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="300">
        <div class="how-it-work-item">
          <span class="number fo">3</span>
          <div class="how-it-work-body">
            <h2 class="fo">Accompagnement client</h2>
            <p class="mb-5 fo">Nous accompagnons nos clients dans la Catégorisation de la société. Ceci leur
              permet de bénéficier de tous les avantages et facilités accordés par la douane
              sénégalaise.</p>
          </div>
        </div>
      </div>

     

      <div class="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="200">
        <div class="how-it-work-item">
          <span class="number fo">4</span>
          <div class="how-it-work-body">
            <h2 class="fo">Traitement Régime économique douanier</h2>
            <p class="mb-5 fo">Nous traitons tous les régimes économiques en douane , acquis à caution, mise à la
              consommation… et par toutes les voies , maritimes, aériennes, routières, et nous
              agissons dans tous les bureaux douaniers du Sénégal.</p>
         
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


