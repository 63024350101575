import { Component, OnInit } from '@angular/core';
import { CorpService } from '../services/corp.service';
@Component({
  selector: 'app-interface',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.css']
})
export class InterfaceComponent implements OnInit {

  constructor(private  cor: CorpService) { }

  ngOnInit() {
  }

  giveAcc(){
    return this.cor.getAcc();
  }
   
  giveTransit(){
    return this.cor.getTransitserv();
  }

  giveTrans(){
    return this.cor.getTranspserv();
  }

  giveConseil(){
    return this.cor.getConseilserv();
  }

  giveConsign(){
    return this.cor.getConsignserv();
  }

  giveContact(){
    return this.cor.getContact();
  }
}
