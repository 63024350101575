import { Injectable } from '@angular/core';
@Injectable()
export class CorpService {

  acc = true;
transitserv = false;
transpserv=false;
consignserv=false;
conseilserv=false;
contact=false;

  setAcc() {
    this.acc = true;
    this.transitserv = false;
    this.transpserv = false;
    this.consignserv = false;
    this.conseilserv = false;
    this.contact=false;

  }
  setTransitserv() {
    this.transitserv = true;
    this.acc = false;
    this.transpserv = false;
    this.consignserv = false;
    this.conseilserv = false;
    this.contact=false;

  }
  setTranspserv() {
    this.transpserv = true;
    this.transitserv = false;
    this.acc = false;
    this.consignserv = false;
    this.conseilserv = false;
    this.contact=false;

  }

  setConsignserv() {
    this.consignserv = true;
    this.transpserv = false;
    this.transitserv = false;
    this.acc = false;
    this.conseilserv = false;
    this.contact=false;

  }
  setConseilserv() {
    this.conseilserv = true;
    this.transpserv = false;
    this.transitserv = false;
    this.acc = false;
    this.consignserv = false;
    this.contact=false;

  }

  setContact() {
    this.contact=true;
    this.conseilserv = false;
    this.transpserv = false;
    this.transitserv = false;
    this.acc = false;
    this.consignserv = false;

  }
  getAcc() {
    return this.acc;
  }

  getTransitserv() {
    return this.transitserv;
  }

  getTranspserv() {
    return this.transpserv;
  }
  getConsignserv(){
    return this.consignserv;

  }
  getConseilserv() {
    return this.conseilserv;

  }

  getContact() {
    return this.contact;

  }

}
