<ngb-carousel>
   
 <ng-template  ngbSlide>
    <img src="../assets/img/service/sabour.png" alt="Random first slide">
          <div class="carousel-caption">

            <h4> Sabour global,UNE NOUVELLE VISION DU TRANSIT  </h4>
            <p class="p1"> La livraison de vos conteneurs avec Sabour global s'effectue dans les plus brief délais </p>

          </div>
        </ng-template>
        <ng-template ngbSlide>
            <img src="../assets/img/service/trans2.jpg" alt="Random second slide">
            <div class="carousel-caption">
              <h4>NOUS VOUS OFFRONS UN MOYEN sûr DE FAIRE PARVENIR VOS CONTENEUR   </h4>
              <p class="p2"> Le dynamisme et l'expertise de nos équipes au service de prestations de qualité </p>
        
            </div>
          </ng-template>
  <ng-template ngbSlide>
    <img src="../assets/img/service/trans5.png" alt="Random second slide">
    <div class="carousel-caption">
              <h4>NOUS VOUS OFFRONS UNE AUTRE MANIERE DE VOIR LE TRANSIT </h4>
              <p class="p3">Transit et numérique un défi relevé chez Sabour global.</p>

    </div>
  </ng-template>

</ngb-carousel>

