import {Subject} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Visiteur } from '../models/visiteur.model';
@Injectable()

export class VisiteurService {
  private visiteurs: Visiteur[] = [];
  EmployeSubject = new Subject<any[]>();
  constructor(private httpClient: HttpClient) {
  }
  saveAppareilsToServer() {
    this.httpClient
      .put('https://testangular-cd05f.firebaseio.com/employes.json', this.visiteurs)
      .subscribe(
        () => {
          console.log('Enregistrement terminé !');
        },
        (error) => {
          console.log('Erreur ! : ' + error);
        }
      );
  }
  emitAppareilSubject() {
    this.EmployeSubject.next(this.visiteurs.slice());
  }
  getVisiteursFromServer() {
    this.httpClient
      .get<any[]>('https://testangular-cd05f.firebaseio.com/employes.json')
      .subscribe(
        (response) => {
          this.visiteurs = response;
          this.emitAppareilSubject();
        },
        (error) => {
          console.log('Erreur ! : ' + error);
        }
      );
  }
  AjouterVisiteur(visiteur: Visiteur ) {
this.getVisiteursFromServer();
    console.log(this.visiteurs);
    this.visiteurs.push(visiteur);
    console.log(this.visiteurs);
    this.saveAppareilsToServer();
    alert('employe ajouté');
    console.log(this.visiteurs);
  }
}
