import { Component, OnInit } from '@angular/core';
import { CorpService } from '../services/corp.service';
@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css']
})
export class BodyComponent implements OnInit {

  constructor(private  cor: CorpService) { }

  ngOnInit() {  }
  AccOn() {
    this.cor.setAcc();
  }

  transitOn(){
    this.cor.setTransitserv();
  }
transpOn(){
  this.cor.setTranspserv();
}
consignOn(){
  this.cor.setConsignserv();
}
conseilOn(){
  this.cor.setConseilserv();
}

contactOn(){
  this.cor.setContact();
}
}
