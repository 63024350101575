<!--header-->
<app-navebar>
  
        <app-slider *ngIf="this.giveAcc()==true"></app-slider>
        <app-body *ngIf="this.giveAcc()==true"></app-body>
        <app-servicetransit *ngIf="this.giveTransit()==true"></app-servicetransit>
        <app-service-transport *ngIf="this.giveTrans()==true"></app-service-transport>
        <app-service-conseil *ngIf="this.giveConseil()==true"></app-service-conseil>
        <app-service-consignation *ngIf="this.giveConsign()==true"></app-service-consignation>

     <app-notre-equipe *ngIf="this.giveTransit()==true ||this.giveTrans()==true ||this.giveConseil()==true || this.giveConsign()==true"></app-notre-equipe>


        <app-contact *ngIf="this.giveContact()==true"></app-contact>

        <!--footer-->
        <app-footer></app-footer>
        
</app-navebar>
<!--body-->
