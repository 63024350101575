<mat-sidenav-container class="sidenav-container ">
  <mat-sidenav  #drawer class="sidenav" fixedInViewport="false"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="!(isHandset$) | async">
    <mat-toolbar>          <img src="../assets/img/service/logo.png" alt=""> Sabour Global </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item  class="example-ripple-container" (click)="AccOn()"><mat-icon>home</mat-icon><span>Acceuil</span></a>
      <mat-accordion>
          <mat-expansion-panel class="example-ripple-container">
            <mat-expansion-panel-header>
              <mat-panel-title >
                  <mat-icon>settings</mat-icon><span>Services</span>              
              </mat-panel-title>
             
            </mat-expansion-panel-header>
            <a mat-list-item  (click)="transitOn()"><span>Transit</span></a>
            <a mat-list-item (click)="transpOn()" ><span>Transport</span></a>
            <a mat-list-item (click)="consignOn()"><span>Consignation</span></a>
            <a mat-list-item (click)="conseilOn()"><span>Conseil</span></a>

          
          </mat-expansion-panel>
        </mat-accordion>
        <a mat-list-item (click)="contactOn()"><mat-icon>contacts</mat-icon><span>Contacts</span></a>
    

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar  class=" navbar-default  fixed-top" >
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      
        
          <img src="../assets/img/service/logo.png" alt="">
          <span class="example-fill-remaining-space"></span>
  
   
            <button  class="example-ripple-container btn  btn-outline-success" (click)="AccOn()"><mat-icon>home</mat-icon><span>Acceuil</span></button>

          <div ngbDropdown placement="down-right" class="d-inline-block">
            <button class="example-ripple-container btn btn-outline-success"  ngbDropdownToggle><mat-icon>settings</mat-icon><span>Services</span></button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic2" style="background-color:white">
              <button class="dropdown-item example-ripple-container" (click)="transitOn()">Transit</button>
              <button class="dropdown-item example-ripple-container" (click)="transpOn()">Transport</button>
              <button class="dropdown-item example-ripple-container" (click)="consignOn()">Consignation</button>
              <button class="dropdown-item example-ripple-container" (click)="conseilOn()">Conseil</button>
            </div>
          </div>
          <button type="button" class="example-ripple-container btn  btn-outline-success"(click)="contactOn()"><mat-icon>contacts</mat-icon><span>Contacts</span></button>

     

  
             
      
    
      
    </mat-toolbar>
    <!-- Add Content Here -->
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
