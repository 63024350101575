<div class="site-section bg-image overlay" style="background-image: url('./assets/img/blog/blog_1.jpg');">
  <div class="container">
    <div class="row justify-content-center mb-5">
      <div class="col-md-7 text-center border-primary">
          <br/>
        <h2 class="font-weight-light text-primary" data-aos="fade"><span style="color: white">Service Consignation</span></h2>
      </div>
    </div>
    <div class="row">

        

      <div class="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="100">
        <div class="how-it-work-item">
          <span class="number fo">1</span>
          <div class="how-it-work-body">
            <h2 class="fo">Consignation de navires</h2>
            <p class="mb-5 fo">Nous prenons en charge des navires,de l'organisation de leur escale mais également du suivi des marchandises transportées à savoir:
               </p>
               <ul class="list-unstyled white">
                  <li class="text-white"><mat-icon>check</mat-icon> Récupération des titres de propriété des marchandises</li>
                  <li class="text-white"><mat-icon>check</mat-icon>Analyse des documents pour l'exportation</li>
                  <li class="text-white"><mat-icon>check</mat-icon> Déclaration aux services des douanes</li>
                  <li class="text-white"><mat-icon>check</mat-icon> Répartition des frais d'escale</li>


                </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-1 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="300">
          <div class="how-it-work-item">
            <div class="how-it-work-body">
          
            </div>
          </div>
        </div>
     

      <div class="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="300">
        <div class="how-it-work-item">
          <span class="number fo">2</span>
          <div class="how-it-work-body">
            <h2>Nous offrons également:</h2>
              <ul class="list-unstyled white">
                <li class="text-white"><mat-icon>check</mat-icon> La manutention</li>
                <li class="text-white"><mat-icon>check</mat-icon>Le dédouanement</li>
                <li class="text-white"><mat-icon>check</mat-icon>L'évacuation</li>
                <li class="text-white"><mat-icon>check</mat-icon>La livraison de toute sorte de marchandise</li>


              </ul>
          </div>
        </div>
      </div>


     

    
    </div>
  </div>
</div>


