import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InterfaceComponent } from './interface/interface.component';
import { SliderComponent } from './slider/slider.component';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BodyComponent } from './body/body.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import { CorpService } from './services/corp.service';
import {HttpClientModule} from '@angular/common/http';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatExpansionModule} from '@angular/material/expansion';

import { NavebarComponent } from './navebar/navebar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule, MatSidenavModule, MatIconModule, MatListModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BdserviceComponent } from './bdservice/bdservice.component';
import {MatRippleModule} from '@angular/material/core';
import { ServiceTransportComponent } from './service-transport/service-transport.component';
import { ServicetransitComponent } from './servicetransit/servicetransit.component';
import { ServiceConseilComponent } from './service-conseil/service-conseil.component';
import { NotreEquipeComponent } from './notre-equipe/notre-equipe.component';
import { ContactComponent } from './contact/contact.component';
import { ServiceConsignationComponent } from './service-consignation/service-consignation.component';
import { VisiteurService } from './services/user.service';




@NgModule({
  declarations: [
    AppComponent,
    InterfaceComponent, SliderComponent,  BodyComponent, FooterComponent, NavebarComponent, BdserviceComponent, ServiceTransportComponent, ServicetransitComponent, ServiceConseilComponent, NotreEquipeComponent, ContactComponent, ServiceConsignationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, NgbModule, FormsModule,  ReactiveFormsModule, HttpClientModule,MatToolbarModule, LayoutModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,BrowserAnimationsModule,MatRippleModule,MatExpansionModule
  ],
  providers: [CorpService, VisiteurService],
  bootstrap: [AppComponent]
})
export class AppModule { }
